<template>
  <div
    id="projectTop"
    class="strategy-edit-main-container mt-3 mb-10 px-10"
    v-if="isLoad"
  >
    <v-container>
      <!-- ヘッダー(戦略パーツ未追加時) -->
      <div v-if="!isStrategyParts">
        <div class="text-h5 font-weight-medium pb-3 mt-3" align="center">
          はじめに
        </div>
        <hr class="page-title-hr" />
        <div class="text-body-1 pt-2 mb-7" align="center">
          「戦略パーツ追加」ボタンをクリックして戦略パーツを追加してください。
        </div>
        <div align="center">
          <v-btn-accent
            v-show="hasWritePermission"
            label="戦略パーツ追加"
            icon="mdi-plus-circle-outline"
            @click="openAddDialog()"
          >
          </v-btn-accent>
        </div>
      </div>
      <!-- ヘッダー(戦略パーツ追加済み時) -->
      <v-row v-else class="strategy-top-header mt-3">
        <v-spacer></v-spacer>
        <v-col cols="12" md="8" class="text-h5 font-weight-medium pa-0" align="center">
          追加した戦略パーツ一覧
        </v-col>
        <!-- 戦略パーツ追加画面へ遷移するボタン -->
        <v-col cols="12" md="2" class="pa-md-0 pb-0" align="center">
          <v-btn-accent
            v-show="hasWritePermission"
            label="戦略パーツ追加"
            icon="mdi-plus-circle-outline"
            @click="openAddDialog()"
          >
          </v-btn-accent>
        </v-col>
        <v-col cols="12" class="pa-0 mt-4 mb-8" align="center">
          <hr class="page-title-hr" />
        </v-col>
      </v-row>
      <!-- 追加済み戦略パーツ一覧 -->
      <filterable-parts-group v-if="isStrategyParts" :sections="sections" />
    </v-container>

    <!-- パーツ追加ダイアログ -->
    <strategy-parts-add-dialog :value="dialog" />
  </div>
  <div v-else class="d-flex align-center justify-center" style="height: 100%;">
    <v-progress-circular
      indeterminate
      color="btPrimary"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FilterablePartsGroup from "./FilterablePartsGroup";
import VBtnAccent from "@/components/atomic/atoms/VBtnAccent.vue";
import StrategyPartsAddDialog from "./StrategyPartsAddDialog.vue";

export default {
  components: {
    FilterablePartsGroup,
    VBtnAccent,
    StrategyPartsAddDialog,
  },
  props: ["readonly", "projectReadonly"],
  data() {
    return {
      isLoad: false,
      sections: false,
    };
  },
  computed: {
    ...mapGetters(["project", "registeredSections", "hasWritePermission"]),
    isStrategyParts() {
      return this.sections.length;
    },
    dialog() {
      return !!this.$route.query.d
    }
  },
  created() {
    console.log("this.projectReadonly")
    this.init();
  },
  methods: {
    ...mapActions(["getProject", "getRegisteredSections"]),
    async init() {
      await this.getProject(this.$route.params.projectId);

      // セクションの一覧を取得
      await this.getRegisteredSections(this.$route.params.projectId);
      this.sections = this.registeredSections.sections.map((section) => {
        return { ...section.section, inputStatus: section.inputStatus };
      });
      this.isLoad = true;
    },
    openAddDialog() {
      this.$router.push({ name: "StrategyTopPage", query: {d: true} });
    }
  },
  watch: {
    "$route.params.projectId": async function(to) {
      this.isLoad = false;
      await this.init();
      this.isLoad = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.strategy-edit-main-container {
  margin: 0 auto;
  max-width: 1180px;
}
.page-title-hr {
  max-width: 1180px !important;
}
</style>
